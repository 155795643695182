<template>
    <div class="home">
        <HomeCarousel></HomeCarousel>
        <div class="py-4"></div>

        <HomeArticles></HomeArticles>
        <div class="py-4"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import HomeCarousel from './HomeCarousel.vue';
import HomeArticles from './HomeArticles.vue';

export default defineComponent({
    name: 'Home',
    components: { HomeCarousel, HomeArticles }
});
</script>
