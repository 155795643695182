
import { ElMenuItem, ElSubmenu } from 'element-plus';
import { defineComponent } from 'vue';

import { ArticleModel } from '@/components/Article/article.model';
import { useObservable } from '@/helpers/rxjs.helper';
import router from '@/router';
import { getArticles } from '@/services/article.service';

export default defineComponent({
    name: 'HeaderMenu',
    components: { ElMenuItem, ElSubmenu },
    setup() {
        const articles = useObservable(getArticles());

        const openRouterLink = (route: string) => router.push(route);

        const openArticlePage = (article: ArticleModel) => openRouterLink(article.route);

        return {
            articles,
            openRouterLink,
            openArticlePage
        };
    }
});
