<template>
    <div class="footer px-4">
        <div class="copyright">© {{ currentYear }} Merrill's Marauders Proud Descendants</div>
        <a
            class="facebook-icon"
            href="https://www.facebook.com/groups/merrillsmarauderspd/"
            target="_blank"
        >
            <img src="@/assets/facebook.png" />
        </a>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Footer',
    setup() {
        return {
            currentYear: 2021
        };
    }
});
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.footer {
    align-items: center;
    background-color: $primary;
    color: $white;
    display: flex;
    height: 61px;
    justify-content: space-between;

    .facebook-icon {
        cursor: pointer;
        height: 30px;
        width: 30px;

        img {
            height: 30px;
            width: 30px;
        }
    }
}
</style>
