export class HomeCarouselSlide {
    public image = '';
    public title: string | undefined = '';
    public text: string | undefined = '';
    public textRight: boolean | undefined = false;

    constructor(image: string, title?: string, text?: string, textRight?: boolean) {
        this.image = image;
        this.title = title;
        this.text = text;
        this.textRight = textRight;
    }
}
