
import { ElCarousel, ElCarouselItem } from 'element-plus';
import { map } from 'rxjs/operators';
import { defineComponent, ref, Ref } from 'vue';

import { checkIfSmallScreenOnResize, ScreenSizes } from '@/helpers/resize.helper';
import { useObservable } from '@/helpers/rxjs.helper';
import { getHomeCarouselSlides } from '@/services/carousel.service';
import { HomeCarouselSlide } from './home-carousel.model';

export default defineComponent({
    name: 'HomeCarousel',
    components: { ElCarousel, ElCarouselItem },
    setup() {
        const slides: Ref<HomeCarouselSlide[]> = useObservable(getHomeCarouselSlides(), []);
        const activeSlide = ref(0);

        const isSmallScreen = useObservable(
            checkIfSmallScreenOnResize().pipe(
                map(screenSize => screenSize === ScreenSizes.xs || screenSize === ScreenSizes.sm)
            )
        );

        const onSlideChange = (index: number) => (activeSlide.value = index);

        return {
            slides,
            activeSlide,
            isSmallScreen,
            onSlideChange
        };
    }
});
