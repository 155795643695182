
import { ElCol, ElDivider, ElMain, ElRow } from 'element-plus';
import { defineComponent } from 'vue';

import { ArticleModel } from './article.model';

export default defineComponent({
    name: 'Article',
    components: { ElCol, ElDivider, ElMain, ElRow },
    props: {
        article: ArticleModel
    }
});
