
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Footer',
    setup() {
        return {
            currentYear: 2021
        };
    }
});
