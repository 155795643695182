
import { defineComponent } from 'vue';

import Article from '@/components/Article/Article.vue';
import { useObservable } from '@/helpers/rxjs.helper';
import { getArticleByTitle } from '@/services/article.service';

export default defineComponent({
    name: 'Events',
    components: { Article },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const article = useObservable(getArticleByTitle('events', props.title));

        return {
            article
        };
    }
});
