import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { onBeforeUnmount, ref, Ref } from 'vue';

const subscribeTo = <T>(
    observable: Observable<T>,
    next?: (value: T) => void,
    error?: (err: string | object | undefined) => void,
    complete?: () => void
) => {
    const unsubscribe$ = new Subject<void>();
    const subscription = observable.pipe(takeUntil(unsubscribe$)).subscribe(next, error, complete);
    onBeforeUnmount(() => {
        unsubscribe$.next();
        unsubscribe$.complete();
    });

    return subscription;
};

export const useObservable = <T>(observable: Observable<T>, defaultValue?: T): Ref<T> => {
    const handler = ref(defaultValue) as Ref<T>;
    subscribeTo(
        observable,
        value => {
            handler.value = value;
        },
        error => {
            throw error;
        }
    );

    return handler;
};
