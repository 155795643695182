import { HomeCarouselSlide } from '@/views/Home/home-carousel.model';

export const HomeCarouselSlidesData = [
    new HomeCarouselSlide(
        'https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Poster.jpg?alt=media',
        'Three of five remaining Merrill’s Marauders attend PBS documentary premiere about their WWII unit',
        '<a href="https://www.greenevillesun.com/news/local_news/3-of-5-surviving-merrill-s-marauders-attend-premiere-of-documentary-on-wwii-unit/article_5a41fd80-69d0-522a-9a13-09fa1bf2d890.html" target="_blank" style="color: white">Click here to read the press release!</a>'
    ),
    new HomeCarouselSlide(
        'https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/marauderscgmsigned-1.png?alt=media',
        'Marauders Congressional Gold Medal Bill Signed into Law',
        'Merrill’s Marauders honored with Congressional Gold Medal!'
    ),
    new HomeCarouselSlide(
        'https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/marauder-slide.png?alt=media',
        'Marauders Congressional Gold Medal Bill Passes in House and Senate!',
        'We did it! Thank you to everyone who helped H.R. 906 reach 290 cosponsors today, July 28, 2020. We are one step closer to honoring our beloved heroes with the esteemed Congressional Gold Medal!',
        true
    ),
    new HomeCarouselSlide(
        'https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/P1b-1080x350.jpg?alt=media',
        '',
        '<b>Merrill’s Marauders Proud Descendants</b> are the descendants, family members and friends of men who fought in a forgotten land, during World War II. These men risked their lives and their futures, and many made the ultimate sacrifice fighting behind the Japanese lines in Burma. This site is dedicated to the memory of all Merrill’s Marauders, those who returned, and those who did not.</br></br><i>Our nation owes them much!</i>'
    ),
    new HomeCarouselSlide(
        'https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/P3-1080x350.jpg?alt=media',
        'A Marauder Displays His Flag',
        'Marauder, and Marauder Historian, Robert “Bob” Passanisi,. was in Co. A, 5307th, Red Combat Team, HHC 1st Battalion, 475th, and is a member of the Ranger Hall of Fame.',
        true
    ),
    new HomeCarouselSlide(
        'https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/P5-1080x350.jpg?alt=media',
        '',
        ''
    )
];
