
import { ElCard, ElCol, ElDivider, ElMain, ElRow } from 'element-plus';
import { defineComponent } from 'vue';

import { useObservable } from '@/helpers/rxjs.helper';
import { getNewsItems } from '@/services/news.service';
import { NewsItem } from './news.model';
import { map } from 'rxjs/operators';

export default defineComponent({
    name: 'Press',
    components: { ElCard, ElCol, ElDivider, ElMain, ElRow },
    setup() {
        const filterAndSortNewsItems = (items: NewsItem[]) =>
            items
                .filter(item => {
                    const title = item.title.toLowerCase();
                    return (
                        (title.includes('merrill') && title.includes('marauder')) ||
                        title.includes('wwii') ||
                        title.includes('world war') ||
                        title.includes('congressional gold medal') ||
                        (title.includes('cfa') && title.includes('medal'))
                    );
                })
                .sort((a, b) => {
                    if (a.published && b.published) {
                        return b.published?.valueOf() - a.published?.valueOf();
                    }

                    if (a.published && !b.published) {
                        return -1;
                    }

                    if (b.published && !a.published) {
                        return 1;
                    }

                    return 0;
                });

        const news = useObservable(getNewsItems().pipe(map(res => filterAndSortNewsItems(res))));

        const openLink = (link: string) => window.open(link, '_blank');

        return {
            news,
            openLink
        };
    }
});
