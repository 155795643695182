<template>
    <div id="app">
        <Header></Header>

        <div class="content-container">
            <router-view :key="$route.fullPath" />
        </div>

        <Footer></Footer>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';

export default defineComponent({
    name: 'App',
    components: { Footer, Header }
});
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #303133;
}

.content-container {
    min-height: calc(100vh - 122px);
}
</style>
