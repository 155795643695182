<template>
    <el-main class="press-page article-content">
        <el-row>
            <el-col
                :xs="24"
                :sm="{ span: 22, offset: 1 }"
                :md="{ span: 18, offset: 3 }"
                :lg="{ span: 14, offset: 5 }"
                :xl="{ span: 10, offset: 7 }"
            >
                <h2>Merrill’s Marauders “In the News”</h2>
                <el-divider></el-divider>
            </el-col>
        </el-row>

        <el-row>
            <el-col
                :xs="24"
                :sm="{ span: 22, offset: 1 }"
                :md="{ span: 18, offset: 3 }"
                :lg="{ span: 14, offset: 5 }"
                :xl="{ span: 10, offset: 7 }"
            >
                <el-card
                    v-for="item in news"
                    :key="item.id"
                    class="news-item"
                    @click="openLink(item.link)"
                >
                    <template #header>
                        <a class="news-title">
                            {{ item.title }}
                        </a>
                        <i class="news-link-icon fas fa-external-link-alt fa-sm"></i>
                    </template>
                    <div class="news-source-date">
                        <div class="news-source">{{ item.source.title }}</div>
                        <div class="news-date">
                            {{
                                item.published.toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })
                            }}
                        </div>
                    </div>
                    <div class="news-item-hover-overlay"></div>
                </el-card>
            </el-col>
        </el-row>
    </el-main>
</template>

<script lang="ts">
import { ElCard, ElCol, ElDivider, ElMain, ElRow } from 'element-plus';
import { defineComponent } from 'vue';

import { useObservable } from '@/helpers/rxjs.helper';
import { getNewsItems } from '@/services/news.service';
import { NewsItem } from './news.model';
import { map } from 'rxjs/operators';

export default defineComponent({
    name: 'Press',
    components: { ElCard, ElCol, ElDivider, ElMain, ElRow },
    setup() {
        const filterAndSortNewsItems = (items: NewsItem[]) =>
            items
                .filter(item => {
                    const title = item.title.toLowerCase();
                    return (
                        (title.includes('merrill') && title.includes('marauder')) ||
                        title.includes('wwii') ||
                        title.includes('world war') ||
                        title.includes('congressional gold medal') ||
                        (title.includes('cfa') && title.includes('medal'))
                    );
                })
                .sort((a, b) => {
                    if (a.published && b.published) {
                        return b.published?.valueOf() - a.published?.valueOf();
                    }

                    if (a.published && !b.published) {
                        return -1;
                    }

                    if (b.published && !a.published) {
                        return 1;
                    }

                    return 0;
                });

        const news = useObservable(getNewsItems().pipe(map(res => filterAndSortNewsItems(res))));

        const openLink = (link: string) => window.open(link, '_blank');

        return {
            news,
            openLink
        };
    }
});
</script>

<style lang="scss" scoped>
.press-page {
    .news-item {
        cursor: pointer;
        font-size: 1rem;
        margin-bottom: 15px;
        position: relative;

        &:hover {
            .news-item-hover-overlay {
                background-color: rgba(0, 0, 0, 0.06);
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .news-link-icon {
            opacity: 0.5;
            position: absolute;
            right: 5px;
            top: 5px;
        }

        .news-source-date {
            display: flex;
            font-size: 0.8rem;
            justify-content: space-between;

            .news-date {
                font-style: italic;
            }
        }
    }

    .article-content {
        margin-bottom: 40px;
    }
}
</style>
