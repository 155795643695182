import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import firebase from 'firebase/app';
import 'firebase/database';
import '@/styles/element-variable.scss';
import '@/styles/styles.scss';

firebase.initializeApp({
    apiKey: 'AIzaSyCkqaVzbWO4lLgFZQUsN4skZTfhGfQu2hI',
    authDomain: 'merrills-marauders-pd.firebaseapp.com',
    databaseURL: 'https://merrills-marauders-pd-default-rtdb.firebaseio.com',
    projectId: 'merrills-marauders-pd',
    storageBucket: 'merrills-marauders-pd.appspot.com',
    messagingSenderId: '295742612112',
    appId: '1:295742612112:web:3af7d5a739fa682488ee15'
});

export const db = firebase.database();

createApp(App)
    .use(router)
    .mount('#app');
