import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { onBeforeUnmount } from 'vue';

export enum ScreenSizes {
    xs = 'Extra Small',
    sm = 'Small',
    md = 'Medium',
    lg = 'Large'
}

export const checkIfSmallScreenOnResize = () => {
    const checkIsExtraSmallScreen = () => window.innerWidth < 768;
    const checkIsSmallScreen = () => window.innerWidth >= 768 && window.innerWidth < 992;
    const checkIsMediumScreen = () => window.innerWidth >= 992 && window.innerWidth < 1200;
    const getScreenSize = () =>
        checkIsExtraSmallScreen()
            ? ScreenSizes.xs
            : checkIsSmallScreen()
            ? ScreenSizes.sm
            : checkIsMediumScreen()
            ? ScreenSizes.md
            : ScreenSizes.lg;

    const screenSizeSource = new BehaviorSubject<string>(getScreenSize());
    const updateScreenSize = () => screenSizeSource.next(getScreenSize());

    window.addEventListener('resize', updateScreenSize);
    const unsubscribe$ = new Subject<void>();
    const subscription = screenSizeSource.pipe(takeUntil(unsubscribe$));

    onBeforeUnmount(() => {
        window.removeEventListener('resize', updateScreenSize);
        unsubscribe$.next();
        unsubscribe$.complete();
    });

    return subscription;
};
