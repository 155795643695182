
import { ElCol, ElContainer, ElHeader, ElMenu, ElRow, ElSubmenu } from 'element-plus';
import { map } from 'rxjs/operators';
import { defineComponent, ref, watch } from 'vue';

import { checkIfSmallScreenOnResize, ScreenSizes } from '@/helpers/resize.helper';
import { useObservable } from '@/helpers/rxjs.helper';
import HeaderMenu from './HeaderMenu.vue';
import router from '@/router';

export default defineComponent({
    name: 'Header',
    components: { ElCol, ElContainer, ElHeader, ElMenu, ElRow, ElSubmenu, HeaderMenu },
    setup() {
        const isSmallScreen = useObservable(
            checkIfSmallScreenOnResize().pipe(
                map(screenSize => screenSize === ScreenSizes.xs || screenSize === ScreenSizes.sm)
            )
        );

        const currentActiveMenu = ref(router.currentRoute.value.name);

        watch(
            () => router.currentRoute.value.name,
            () => (currentActiveMenu.value = router.currentRoute.value.name)
        );

        return {
            isSmallScreen,
            ScreenSizes,
            currentActiveMenu
        };
    }
});
