import { HomeArticle } from '@/views/Home/home-article.model';

export const HomeArticleData = [
    new HomeArticle(
        'Explore Marauder History!',
        `<div><p style="text-align: center;"><a href="http://www.amazon.com/Spearhead-Complete-History-Merrills-Marauder/dp/0989839400/ref=sr_1_1?ie=UTF8&amp;qid=1428175697&amp;sr=8-1&amp;keywords=spearhead+marauder+book"><img loading="lazy" class="aligncenter wp-image-304" style="border: 1px solid #000000;" src="https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/spearhead-cover.jpg?alt=media" alt="spearhead-cover" width="150" height="225" srcset="https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/spearhead-cover.jpg?alt=media 200w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/spearhead-cover.jpg?alt=media 267w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/spearhead-cover.jpg?alt=media 133w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/spearhead-cover.jpg?alt=media 288w" sizes="(max-width: 150px) 100vw, 150px"></a><br><br><strong>“A Complete History of Merrill’s Marauders Rangers”
        </strong>By Dr. James E. T. Hopkins &amp; John M. Jones<br>
        Available at <a href="http://www.amazon.com/Spearhead-Complete-History-Merrills-Marauder/dp/0989839400/ref=sr_1_1?ie=UTF8&amp;qid=1428176953&amp;sr=8-1&amp;keywords=spearhead+marauder+book" target="_blank">Amazon.com<br>
        </a><em>All proceeds from the sale of this book go to</em><br>
        <em> The MERRILL’S MARAUDERS ASSOCIATION<br>
        — </em>740 pages, 28 maps, 52 photos, index. —</p></div>`
    ),
    new HomeArticle(
        'Organization Interested in Producing Marauder Documentary',
        '<div><p>The organization, WWII Foundation, produces documentaries on military units, and they\'re interested in producing a new Merrill\'s Marauders documentary! Please visit their website to make donations:</p> <a href="http://www.wwiifoundation.org" target="_blank">http://www.wwiifoundation.org</a>.</div>'
    ),
    new HomeArticle(
        'Important Marauder Book Re-Printed!',
        `<div><h3 style="text-align: center;"><span style="text-decoration: underline;"><span style="color: #000080; text-decoration: underline;">A True American Hero – <em>“The Fightin’ Preacher”</em></span></span></h3>
        <p style="text-align: center;">His God called him to preach.<br>
        His country called him to fight.<br>
        His men called him …<br>
        “The Fightin’ Preacher”</p>
        <p style="text-align: center;">Colonel Logan E. Weston, U.S. Army (Deceased) was a veteran of three wars: World War II, Korean and Vietnam. He went from Guadalcanal and new Georgia in World War II, to volunteer with the renowned “Merrill’s Marauders” in Burma, through Korea with the&nbsp; “Wolfhounds” and onto the build-up of the Vietnam War. Drafted out of Bible College, his faith in God sustained him even was he was deep in enemy country. His unwavering devotion to the God he loved and the country he served earned him a larger-than-life reputation and the undying devotion from his men. A very heavily decorated war hero in U.S. military history he has received many awards and citations. His commendations included the Distinguished Service Cross, Legion of Merit, Silver Stars, Bronze Stars, Purple Hearts, and Combat Infantryman Badges, and more. Col. Weston is a member of the Inaugural class of the U.S. Army Ranger Hall of Fame at Fort Benning, GA. This is the true and inspiring story of the honor,&nbsp; courage and faith of a great American hero.</p>
        <h6 style="text-align: left;">“This is a story of Duty and Honor, Sacrifice and Accomplishment of a great American Patriot. Colonel Weston survived 3 wars under unbelievable and adverse circumstances. His faith in God sustained him throughout those trying times and he has been an inspiration to his men and his many comrades, his many friends, and his<br>
        family.” <span style="color: #000000;"><em>-CPT Phillip B. Piazza, USA, Original President of Merrill’s Marauder Association</em></span></h6>
        <p style="text-align: left;"><a href="http://www.merrillsmarauderspd.org/the-fightin-preacher/" rel="attachment wp-att-985"><img loading="lazy" class="alignright wp-image-985" src="https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392.jpg?alt=media" alt="fightin-preacher" width="329" height="253" srcset="https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392.jpg?alt=media 1199w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392.jpg?alt=media 300w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392.jpg?alt=media 768w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392-1024x786.jpg?alt=media 1024w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392.jpg?alt=media 391w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392.jpg?alt=media 521w, https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher-e1475171886392.jpg?alt=media 261w" sizes="(max-width: 329px) 100vw, 329px"></a></p>
        <h3 style="text-align: center;"><span style="color: #339966;"><em>Now Available!</em></span></h3>
        <p style="text-align: left;"><span style="color: #339966;">Cost $20</span> (includes S/H).&nbsp; Bulk rate discounts are available. <span style="text-decoration: underline;">Part of the proceeds from each book sold through Merrill’s Marauders Proud Descendants will be donated to the association.</span> Autobiography, Military, History, Inspirational 6” x 9” Paperback: 312 pages, color cover, black and white art and photos.</p>
        <p style="text-align: left;"><em>Send check to:</em> Sherry Cowling, 77 Via Larga Vista, Bonsall, CA&nbsp; 92003; mcowling07@hotmail.com</p>
        <h4 style="text-align: left;"><span style="color: #339966;"><em>Please reference this website when ordering, in order to benefit the MMPD Association!</em></span></h4>
        <p>Want to help promote this wonderful book, and gain proceeds for the Merrill’s Marauders Proud Descendants Association? Click the following link to download the flyer and distribute as you please! Thanks!</p>
        <p><a href="https://firebasestorage.googleapis.com/v0/b/merrills-marauders-pd.appspot.com/o/Fightin-Preacher.pdf?alt=media">Downloadable Fightin’ Preacher Flyer</a></p>
        <p>&nbsp;</p></div>`
    ),
    new HomeArticle(
        'GoFundMe for Marauders documentary',
        `<div>
            <p>The organization, WWII Foundation, produces documentaries on military units, and they're interested in producing a new Merrill's Marauders documentary! Please visit their Go Fund Me page to make a donation. Anyone donating $100 or more will be listed in the credits in the film.</p>
            <a href="https://www.gofundme.com/f/special-wwii-documentary-needs-your-support?utm_medium=email&utm_source=product&utm_campaign=p_email%2B2300-co-team-welcome&fbclid=IwAR2oKIXWeMUyIgSGhOKNlZxW27Y6TXu0w7nJktk9u49gWIaFcCfJpIBt3sU" target="_blank">
            <img style="width: 100%" src="https://d2g8igdw686xgo.cloudfront.net/59365795_1629840744273835_r.jpeg" />
            </a>
            <a href="https://www.gofundme.com/f/special-wwii-documentary-needs-your-support?utm_medium=email&utm_source=product&utm_campaign=p_email%2B2300-co-team-welcome&fbclid=IwAR2oKIXWeMUyIgSGhOKNlZxW27Y6TXu0w7nJktk9u49gWIaFcCfJpIBt3sU" target="_blank">Click here to donate or read more</a>
        </div>`
    ),
    new HomeArticle(
        "Merrill's Marauder Congressional Gold Medal Ceremony",
        `<div>
            <p>Please click the link below for the most current information about the long-awaited Merrill's Marauder Congressional Gold Medal ceremony -- which will be virtual.  And please carefully read the information. At this time this is the most current information available. </p>
            <a href="http://www.icontact-archive.com/archive?c=1802913&f=35169&s=39762&m=195149&t=eb08ebfe7072c4796cede8414e00cffae63ad1b026f178e5a36ce51fabb94771" target="_blank">Merrill's Marauder Congressional Gold Medal Ceremony</a>
        </div>`
    )
];
