import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import About from '@/views/About/About.vue';
import Events from '@/views/Events/Events.vue';
import Home from '@/views/Home/Home.vue';
import Links from '@/views/Links/Links.vue';
import Photos from '@/views/Photos/Photos.vue';
import Press from '@/views/Press/Press.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about/:title',
        name: 'about',
        component: About,
        props: true
    },
    {
        path: '/events/:title',
        name: 'events',
        component: Events,
        props: true
    },
    {
        path: '/photos/:title',
        name: 'photos',
        component: Photos,
        props: true
    },
    {
        path: '/press',
        name: 'press',
        component: Press
    },
    {
        path: '/links/:title',
        name: 'links',
        component: Links,
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
