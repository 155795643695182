
import { ElCard, ElContainer } from 'element-plus';
import { map } from 'rxjs/operators';
import { defineComponent, Ref } from 'vue';

import { useObservable } from '@/helpers/rxjs.helper';
import { getHomeArticles } from '@/services/article.service';
import { HomeArticle } from './home-article.model';

export default defineComponent({
    name: 'HomeArticles',
    components: { ElCard, ElContainer },
    setup() {
        const items: Ref<HomeArticle[]> = useObservable(
            getHomeArticles().pipe(map(articles => articles.reverse())),
            []
        );

        return {
            items
        };
    }
});
